.customer-tabLink {
    .nav-link {
        border: none !important;
        color: #000 !important;
        background-color: #fff !important;
        margin-right: 10px;
        border-radius: 10px !important;
        transition: 0.5s ease-in-out;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    .nav-link.active {
        color: #fff !important;
        background-color: #8466e7 !important;
    }
    .nav-link.active-delete {
        color: #fff !important;
        background-color: red !important;
    }
}
.notification-status {
    width: 50px !important;
    height: 30px;
    cursor: pointer;
}
.form-switch {
    display: flex;
    align-items: center;
    label {
        padding-right: 40px;
    }
}
