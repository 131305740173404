.payment-detail-card{
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  .title{
    color: #000;
    font-size: 14px;
    margin: 10px;
  }
  .value{
    font-size: 16px;
    font-weight: 500;
   
    p{
      font-size: 14px;
      display: flex;
    }
    span{
      background-color: #704de7;
      width: 22px;
      height: 22px;
      color: #fff !important;
      display: flex;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
    }
  }
}