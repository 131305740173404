.sa-action{
  min-width: 50px;
}
.dept-header-con{
  background-color: #fff;
  // display: flex;

}
.dept-header-con {
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  flex-wrap: wrap;
  .dept-header-left-con{
    width: 50%;
  }
  .dept-header-right-con{
    width: 50%;
  }
}

.case-name-col-dpts{
  width: 180px;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
  .dept-header-con {
    justify-content:flex-start;
    flex-direction: row;
    .dept-header-typo-sec{
      min-width: 33.33%;
    }
  }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  .dept-header-con {
    justify-content: space-between;
    flex-direction: row;
    .dept-header-left-con{
      width: 60%;
    }
    .dept-header-right-con{
      width: 40%;
    }
  }

 }

 // Medium devices (tablets, 768px and up)
@media (min-width: 1400px) { 
  .dept-header-con {
    justify-content: space-between;
    flex-direction: row;
    .dept-header-left-con{
      width: 50%;
    }
    .dept-header-right-con{
      width: 50%;
    }
  }

 }

  // Medium devices (tablets, 768px and up)
@media (min-width: 1600px) { 
  .dept-header-con {
    justify-content: space-between;
    flex-direction: row;
    .dept-header-left-con{
      width: 55%;
    }
    .dept-header-right-con{
      width: 45%;
    }
  }

 }