.cs-auth-wrapper {
    .auth-right-text-con {
        width: 100%;
    }
    .auth-left-img-con {
        display: none;
    }
    .img-fluid-auth-logo {
        width: 100%;
    }
    .login-img {
        height: 94vh;
    }
    .login-type {
        font-family: "Urbanist", sans-serif;
        font-weight: 500;
        font-size: 1.7rem;
        letter-spacing: 0.03em;
        color: #000000;
    }
    .auth-wrap-right-content {
        box-shadow: -7px 0px 66px #00000073;
    }
    .login-register-typo,
    .login-forget-pass-typo,
    .login-btn-typo {
        font-family: "Urbanist", sans-serif;
        font-weight: 700;
        letter-spacing: 0.03em;
    }
    .login-register-typo,
    .login-forget-pass-typo {
        text-decoration: underline !important;
    }
    .login-btn-typo,
    .login-register-typo {
        font-size: 1.4em;
    }
    .login-forget-pass-typo {
        font-size: 1.235em;
    }
    .login-btn-typo {
        font-weight: 600;
    }
    a {
        &:focus {
            text-decoration: underline !important;
        }
    }

    /*******FORGET PASSWORD******/

    .forget-pass-info-type {
        font-family: "Urbanist", sans-serif;
        font-weight: 600;
        line-height: 22px;
        font-size: 1em;
        letter-spacing: 0.02em;
        color: #8566e7;
    }

    .phone-num-con {
        border: 1px solid #704de7;
        border-radius: 5px;
        width: 100%;
        height: 45px;
        input {
            font-family: "Urbanist", sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0.03em;
            color: rgba(0, 0, 0, 0.8);
            padding: 5px;
            border: none;
            height: 90%;
        }
        .PhoneInputCountry {
            border-right: 1px solid #704de7;
            padding-right: 5px;
            padding-left: 5px;
            .PhoneInputCountryIcon {
                box-shadow: none;
                width: 100%;
                height: 85%;
                background: none;
                line-height: inherit;
                .PhoneInputCountryIconImg {
                    width: 100%;
                    height: 100%;
                    border-bottom-left-radius: 5px;
                    border-top-left-radius: 5px;
                    border: 1px solid #bababa;
                }
            }
        }
        &:focus,
        &:focus-within {
            border: 1px solid #704de7;
        }
    }
    .resend-otp-typo {
        font-family: "Urbanist", sans-serif;
        font-weight: 700;
        font-size: 1.2em;
        letter-spacing: 0.03em;
        text-decoration-line: underline !important;
    }
    .forget-vc-ip {
        margin-left: 15px;
    }
    .forget-vc-input {
        width: 20%;
        height: 3rem;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        font-size: 1.01562rem;
        text-align: center;
        margin-right: 5%;
        border: 1px solid #704de7;
        text-transform: uppercase;
        background: #ffffff;
    }
    .bv-forget-wrap {
        .bv-auth-code {
            div:last-child {
                // &:last-child{
                margin-right: 0px !important;
                // }
            }
            input:focus {
                border: 1px solid #704de7;
            }
        }
    }
    .margin-top-vh {
        margin-top: 20vh;
    }
    .margin-top-vh-vc {
        margin-top: 15vh;
    }
}



