.notification-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.notification-container {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  .approved {
      background-color: #704de7;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
  }
  span{
    font-size: 18px;
    color: #000;
    font-weight: 600;
    // padding-bottom: 0px;
  }
  span:first-child {
      font-size: 16px;
      color: #3E3E40;

  }

  p{
    color: #000;
    font-weight: 500;
    font-size: 16px;
  }
}
