.payment-detail {
    .payment_customer_detail {
        background-color: #fff;
        border-radius: 5px;
    }
    h6 {
        font-family: 14px;
        color: #704de7;
    }
    p {
        color: #222;
        font-size: 18px;
    }
}
