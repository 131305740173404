.trademark-card {
    background-color: #fff;
    border-radius: 5px;
    padding: 12px;
    margin: 5px;
    margin-bottom: 25px;
}
.trademark-card {
    button {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
    }
    .status {
        font-weight: 600;
        display: flex;
        align-items: center;
    }
    .Open,
    .Pending {
        color: #08b256;
    }
    .Closed,
    .Rejected {
        color: #f34d4d;
    }
    .Awaiting {
        color: #f69e1a;
    }
    .Closed {
        span {
            background-color: #f34d4d;
        }
    }
    .Completed {
        color: #08b256;
    }
    .FileDownload {
        color: #704de6;
    }
    .Completed {
        span {
            background-color: #08b256;
        }
    }
    .status {
        span {
            width: 9px;
            height: 9px;
            display: block;
            border: 1px solid;
            border-radius: 50%;
            margin-right: 5px;
        }
    }
    .date {
        font-weight: 500;
    }
    .date {
        .date-value {
            font-weight: 600;
            padding-left: 5px;
        }
    }
    .title {
        text-align: left;
        font-size: 16px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #dedede;
    }
    .title {
        span {
            font-weight: 500;
        }
    }
    .title {
        p {
            font-weight: 600;
        }
    }
    .process-detail {
        padding: 10px 0 0 0;
    }
    .process-detail {
        .process-stage {
            display: flex;
        }
        .process-stage {
            span {
                padding-right: 5px;
            }
        }
        .process-stage {
            font-weight: 600;
        }
        .process-stage {
            .process-stage-value {
                color: #704de6;
            }
            .process-stage-value {
                span {
                    color: #fff;
                    background-color: #704de6;
                    border-radius: 50%;
                    padding: 3px 5px;
                    font-weight: 600;
                    margin-right: 5px;
                }
            }
        }
        .process-status {
            font-weight: 600;
        }
        .process-status {
            span {
                padding-left: 10px;
            }
        }
    }
}
// Modal Css Start
.modal-detail {
    h2 {
        font-weight: 600;
        color: #000;
    }
    h6 {
        color: #000;
        font-weight: 600;
    }
    ul {
        li {
            list-style-type: lower-alpha;
            padding: 5px 0;
            color: #000;
        }
    }
}
.payment-mathod-modal {
    .iframe-container {
        height: 270px;
    }
    .form-check {
        padding: 0 !important;
    }
    .payment-card {
        label {
            height: 100px;
            width: 130px;
            background-color: #f0f0f0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }
        input[type="radio"] {
            display: none;
        }

        input {
            &:checked {
                + {
                    label {
                        border: 1px solid #704de7 !important ;
                        background-color: #fff !important;
                    }
                }
            }
        }
    }
    span {
        font-size: 16px;
        font-weight: 600;
    }

    p {
        font-size: 20px;
        font-weight: 700;
    }
}
.payment-detail-modal {
    padding: auto 10px;
    .card-detail-fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #000;
        border-bottom: 1px solid #000;
        padding-right: 10px;
    }
    span {
        padding-left: 10px;
        color: #000;
        font-size: 16px;
    }
}

.trademark-registration-modal {
    .PhoneInput {
        padding: 12px 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        background-color: #fff;
        font-size: 16px;
        .PhoneInputCountrySelect {
            border-right: 1px solid #dedede;
        }
    }
    .css-19me44h-control {
        border: none !important;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    input,
    .input-group {
        border: none;
    }
}

// Modal Css End
