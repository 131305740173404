.add-trademark-container {
    .accept-trademark-btn {
        background-color: #fff;
        color: #704de7;
        padding: 10px 15px;
        border-radius: 5px;
        border: 1px solid #704de7;
        font-weight: 600;
        font-size: 14px;
        transition: all 0.5s ease-in-out;
        margin-bottom: 20px;

        svg {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            margin-bottom: 4px;
        }
    }

    .accept-trademark-btn.unchecked {
        background-color: #fff;
        color: #704de7;
    }

    .accept-trademark-btn.checked {
        background-color: #2bb44e;
        color: #fff;
        border-color: #2bb44e;
    }

    .case-detail-accordian-body {
        display: flex;
        align-items: center;
        margin-right: 10px;
        border-radius: 4px;
        height: 90px;
        width: 90px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #f2eeff;
        padding: 10px 0;

        .case-detail-accordian-body-img.thumbnail-img-con {
            width: 100%;
            height: 100%;
        }

        .case-detail-accordian-body-img:not(.thumbnail-img-con) {
            width: 25px;
            height: 27px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .case-detail-accordian-body-trademark-body {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 80px;

        p {
            font-family: "Urbanist", sans-serif;
            font-weight: 600;
            font-size: 15px;
        }

        .trademark-body-button-container {
            display: flex;
        }
    }
}

.e-sign-form {
    .case-detail-accordian-body {
        .case-detail-accordian-body.img-con {
            border-radius: 4px;
            height: 90px;
            width: 90px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #f2eeff;
            padding: 10px 0;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.trademark {
    .tradmark-search-form {
        padding: 0px 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #704de694;
        border-radius: 5px;
        width: 30%;

        input {
            width: 100%;
        }

        .filter-btn {
            padding: 8px 10px;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;
            opacity: 0.5;
            svg {
                path {
                    fill: #7e5fe5;
                }
            }
        }

        button {
            background-color: transparent;
            border: none;
        }

        button[type="submit"] {
            background-color: #7e5fe5;
            border: none;
            height: 40px;
            padding: 10px;
            border-radius: 5px;
            margin-left: 10px;
        }

        button[type="submit"] {
            svg {
                path {
                    fill: #fff;
                }
            }
        }

        input {
            border: none;
            background-color: transparent;
        }
    }
    .reset-btn {
        background: #704de7;
        border: none;
        padding: 0;
        font-weight: 900;
        border-radius: 50%;
        cursor: pointer;
        color: #ffffff;
        width: 20px;
        height: 20px;
        margin-left: 10px;
        margin-bottom: 1px;
    }
    .pagination {
        justify-content: end !important;
        padding-right: 0 !important;
    }
}
.filterLabel {
    color: #000;
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #dedede;
}
.form-tabs {
    position: relative;
    display: flex;
    justify-content: space-between;

    .step {
        background-color: #7e5fe5;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
    }

    .step.active {
        opacity: 1;
        box-shadow: #49505700 -20px 11px 4px, rgb(101, 73, 194) 4px 3px 0px 3px;
    }

    .step.disabled {
        opacity: 0.9;
    }

    &:after {
        position: absolute;
        content: "";
        width: 80%;
        height: 5px;
        border-radius: 5px;
        top: 47%;
        left: 0;
        background: #d9d9d9;
        z-index: -1;
        margin: 0 10%;
    }
}

.add-trademark-container {
    padding: 0 15%;
}

.card-logo {
    border: 2px solid transparent;
    padding: 10px;
    transition: border 0.3s ease;
}

.card-logo.selected {
    border-color: #007bff; /* Or any color you prefer for the selected state */
}

.bv-text-input {
    .input-group {
        border-radius: 5px;
    }
}

.btn-row {
    display: flex;

    .continue-btn {
        border-radius: 5px;
        display: inline;
        width: 40%;
    }

    .cancel-btn {
        font-weight: 600;
        font-size: 1rem;
        border-radius: 5px;
        width: 40%;
        background-color: #fff;
        color: #ff0040;
        border: 0;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
}

.place-order-image-con {
    flex-direction: column;
    width: 150px;
    background-color: #fff;
    border: 1px solid #704de6;
    border-radius: 5px;
    padding: 25px 15px;
    margin-top: 10px;
    color: #704de6;
    font-weight: 600;
}

.country-select {
    width: 50%;
}

.greetings {
    margin-top: 30px;

    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 24px;
        color: #7e5ee4;
        font-weight: 400;
        margin-bottom: 0;
    }
    .long-text {
        word-wrap: break-word; /* Allows words to break if they are too long */
        word-break: break-all; /* Forces long words to break at any character */
        white-space: normal; /* Ensures text wraps normally to the next line */
    }

    hr {
        width: 97% !important;
        margin-left: 15px;
        margin-top: 10px !important;
        border: 1px solid #704de766;
        opacity: 1;
    }
}

.splasher {
    .modal-content {
        background-color: #704de6;
        padding: 40px 20px 60px 20px;
        border: none !important;

        p {
            font-size: 18px;
            color: #fff !important;
            text-align: center;
            padding: 50px 0;
        }
    }

    .splasher-btn {
        margin-right: 10px;
        width: 20%;
    }
}

.e-sign-form {
    margin-top: 10px;
    .accept-trd-label {
        display: block;
        font-size: 16px;
        font-weight: 600;

        input {
            width: 15px;
            height: 12px;
            margin-right: 10px;
            margin-top: 20px;
        }
    }
}

.payment-succesful svg {
    fill: #333;
    background-color: #ff0;
}

@media (max-width: 1200px) {
    .add-trademark-container {
        padding: 2rem;
        .bv-text-input {
            width: 100%;
        }
    }
}
@media (max-width: 1052px) {
    .process-detail {
        .row {
            display: block;
            .col-md-8 {
                width: 100% !important;
                padding-bottom: 5px;
            }
            .col-md-4 {
                width: 100%;
                text-align: left !important;
            }
        }
    }
    .trademark {
        .tradmark-search-form {
            width: 50% !important ;
        }
    }
    .trademark-card .date .date-value {
        padding-left: 5px;
    }
}
@media (max-width: 991px) {
    h1 {
        font-size: 24px;
    }
    h2 {
        font-size: 19px;
    }
    h3 {
        font-size: 16px;
    }
    h4 {
        font-size: 14px;
    }
}
@media (max-width: 993px) {
    .trademark {
        .tradmark-search-form {
            width: 100% !important;
        }
    }

    .add-trademark-container {
        padding: 0.8rem;
        .bv-text-input {
            width: 100% !important ;
        }
    }
    .country-select {
        width: 100% !important ;
    }
}
@media (max-width: 770px) {
    .trademark-card {
        .text-end {
            text-align: left !important;
            padding-top: 10px;
        }
    }
    .trademark {
        .tradmark-search-form {
            form {
                div {
                    width: 100%;
                }
            }
        }
    }
}
@media (max-width: 700px) {
    .form-tabs {
        .step {
            width: 80px;
            height: 80px;
            font-size: 16px;
        }
    }
}
@media (max-width: 550px) {
    .form-tabs {
        .step {
            width: 60px;
            height: 60px;
            font-size: 14px;
        }
    }
}
@media (max-width: 400px) {
    .form-tabs .step {
        width: 55px;
        height: 55px;
        font-size: 12px;
    }
}
