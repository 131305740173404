.chat_card {
    background-color: #fff;

    padding: 20px;
    border-radius: 5px;
    border: 1px solid transparent;
    transition: 0.5s ease-in-out;
    // &:hover {
    //     border: 1px solid #7e5fe5;
    // }
    h4 {
        font-weight: 600;
        margin: 1rem 0 !important;
    }
    .process {
        color: #000 !important;
        font-weight: 500;
        .process_value {
            padding-left: 10px;
            color: #7e5fe5 !important;
        }
    }

    .status {
        color: #000 !important;
        font-weight: 500;
        .Completed  {
            padding-left: 10px;
            color: #7e5fe5 !important;
        }
        .PendingCustomer{
            padding-left: 10px;
            color: #f69e1a !important;
        }
        .PendingAdmin {
            padding-left: 10px;
            color: #08b256 !important;
        }
    }
}
