.payment-card-detail {
    .payment-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        font-size: 16px;
        font-weight: 600;
        color: #000;
        margin-bottom: 10px;
    }
    .form-check {
        padding: 0 !important;
        margin-bottom: 10px;
        padding: 0px 20px !important;
        border-radius: 5px;
        background-color: #dedede;
    }
    .form-check .form-check-input {
        margin-left: 0 !important;
        margin-right: 0.5rem;
    }
}
.form-check {
    padding: 0 !important;
}
.form-field {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #000;
    margin-bottom: 20px;
    input {
        border: none;
        display: flex;
        align-items: center;
        width: 100%;
        background-color: transparent;
    }
    .css-1lue4ss-control {
        border: none;
        background-color: transparent;
        padding: 0;
        min-height: 100% !important;

        .status-header-search-admin {
            padding: 0 !important ;
        }
    }
}
