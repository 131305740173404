.confirmation-modal {
    display: flex;
    padding: 0px 20px 30px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
        font-size: 22px;
        font-weight: 700;
    }
    .confirmation-image {
        padding: 10px 0px;
    }
    p {
        color: #000;
        font-size: 16px;
        text-align: center;
    }
    button {
        width: 80%;
    }
}
