.alert-modal {
    display: flex;
    padding: 30px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
        font-size: 22px;
        font-weight: 700;
    }
    .alert-image {
        padding: 20px 0px;
    }
    p {
        color: #000;
        font-size: 16px;
        text-align: center;
    }
    button{
      width: 80%;
    }
}
