.customer-payment-tab {
    .nav-link {
        border: none !important;
        color: #000 !important;
        background-color: #fff !important;
        margin-right: 10px;
        border-radius: 10px !important;
        padding: 12px 20px;
        transition: 0.5s ease-in-out;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    
   
}


