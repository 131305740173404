.chat-form {
    .form-check-input[type="radio"] {
        display: none;
    }
    label {
        width: 100%;
        border: 1px solid #fff !important ;
    }
    label:hover{
        border: none;
    }
    input[type="radio"] {
        &:checked {
            + {
                label {
                    border: 1px solid #704de7 !important ;
                    border-radius: 5px;
                    // background-color: #fff !important;
                    color: #704de7;
                }
            }
        }
    }
}
.chat-form + div {
    nav {
        padding-right: 0 !important;
        margin-right: -5px;
    }
}
.fancy-scroll {
    overflow-y: auto;
    &::-webkit-scrollbar-track {
        background-color: #e6e7e8;
        width: 3px;
    }
    &::-webkit-scrollbar {
        width: 3px;
        background-color: #e6e7e8;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #704de7;
        width: 3px;
        height: 12px;
        border-radius: 10px;
    }
}