.profile-setting-form {
    width: 80%;
    label {
        font-size: 16px;
        font-weight: 600;
    }
    .input-group,
    input {
        border: none !important;
    }
    .PhoneInput {
        padding: 12px 10px;
        background-color: #eff2f7;
        font-size: 1rem;
        box-shadow: 0px 3px 12px #706e6e29;
        border-radius: 5px;
        pointer-events: none;
        input {
            background-color: #eff2f7;
        }
    }
    button {
        padding: 10px 15px;
        border-radius: 5px;
        color: #fff;
        background-color: #8466e7;
        border: none;
        font-weight: 600;
    }

    .delete-btn {
        background-color: red;
    }
}

.change-password-title {
    h1 {
        font-size: 34px;
        color: #000;
    }
    p {
        color: #8566e7;
        font-size: 22px;
        font-weight: 600;
    }
}

.reset-pass-modal-con {
    .cs-auth-wrapper {
        .bv-auth-code {
            .forget-vc-input {
                width: 4rem;
                height: 2.5rem;
                //   width: 20%;
                //   height: 3rem;
                padding: 0.5rem 1rem;
                border-radius: 5px;
                font-size: 1.01562rem;
                text-align: center;
                //   margin-right: 5%;
                margin-right: 16px;
                border: 1px solid #704de7;
                text-transform: uppercase;
                background: #ffffff;
            }
            div {
                &:last-child {
                    margin-right: 0px;
                }
            }
        }
    }
}
