// Preloader CSS Start
.mobile-logo {
    display: none !important;
}
@keyframes loading {
    from {
        transform: rotate(0turn);
    }
    to {
        transform: rotate(1turn);
    }
}
.loader {
    transition: 1s ease;
    animation: loading 1s ease infinite;
}
.loader {
    svg {
        width: 40px;
        height: 40px;
    }
}
.splasher {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
}
// Preloader CSS End
.customer-login {
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
}
.customer-login-form {
    .title {
        h1 {
            font-size: 34px;
            color: #000;
        }
        p {
            color: #8566e7;
            font-size: 22px;
            font-weight: 600;
        }
    }
    .phone-num-con {
        border: none !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background-color: #fff;
        padding: 5px 0;
    }
    .PhoneInputCountry {
        border-right: 1px solid #dedede !important;
        margin: 0;
    }
    .PhoneInputInput {
        border-radius: 5px;
        background-color: #fff;
        padding: 10px !important;
    }
    .input-group {
        border-radius: 5px;
        border: none !important;
        input {
            padding: 10px !important;
        }
    }
    .appended-icon-pre {
        background-color: #fff;
    }
    .appended-icon {
        padding: 10px !important;
    }
    .PhoneInputCountrySelectArrow {
        margin-left: 10px;
        margin-right: 6px;
        font-size: 20px;
        color: #000;
    }
}

.customer-resend-otp-typo {
    font-family: "Urbanist", sans-serif;
    font-weight: 700;
    font-size: 1.2em;
    letter-spacing: 0.03em;
    text-decoration-line: underline !important;
    color: #704de7;
}

@media (max-width: 991px) {
    .cs-auth-wrapper {
        justify-content: center;
        .customer-login-form {
            h1 {
                font-size: 20px;
            }
            p {
                font-size: 14px;
            }
        }
        .mobile-logo {
            display: flex !important;
            padding: 0px 10px;
            img {
                width: 100%;
            }
        }
        .col-md-6 {
            width: 100%;
        }
        .auth-left-img-con {
            display: none !important ;
        }
        .auth-left-img-con {
            display: flex;
        }
        .auth-right-text-con {
            width: 41.66667%;
        }
    }
}
