.trademark-status-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h5 {
        font-size: 24px;
        font-weight: 600;
        color: #000 !important;
    }
    p {
        text-align: center;
        color: #000;
        font-size: 18px;
        padding-bottom: 20px;
        font-weight: 600;
    }
}

.text-container {
    width: 400px; /* Set a width for the container */
    overflow-wrap: break-word; /* Allow long words to break and wrap onto the next line */
    white-space: normal; /* Default whitespace handling */
}
