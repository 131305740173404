.filter-modal {
    .modal-content {
        background-color: #f5f5f5;
    }
    svg {
        background-color: #f5f5f5;
    }

    h6 {
        font-weight: 700;
        font-size: 18px;
        color: #000;
    }
    form {
        padding-left: 10px;
    }
    .form-option {
        // border: 1px solid #dedede;
        // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 10px !important;
        display: flex;
        align-items: center;
        border-radius: 5px;
        margin-bottom: 10px !important;
        font-size: 14px;
        background-color: #fff;
        .form-check-input {
            margin: 0 !important;
            margin-right: 10px !important;
            display: none;
        }
    }
    input{
      display: none;
    }
    .btn{
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
    }
    input {
        &:checked {
            + {
                label {
                    border: 1px solid #704de7 !important ;
                    // background-color: #fff !important;
                    color: #704de7;
                }
            }
        }
    }
}

.custom-checkbox {
  background-color: #000;
}
